.page-header-wrapper {
    position: relative;
    text-align: center;
    max-height: 400px;
    overflow: hidden;
}


.page-header-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);

    padding: 7rem 0 2rem;
    color: $white-color-ev;
}

.page-sub-heading {
    margin-bottom: 0.5rem;
}

@media screen and (min-width: 600px) {
    .page-header-content {
        padding: 10rem 0 2rem;
    }
}

@media screen and (min-width: 768px) {
    .page-header-wrapper {
        max-height: 480px;
        overflow: hidden;
    }

    .page-header-content {
        padding: 12rem 0 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .page-header-wrapper {
        max-height: 540px;
        overflow: hidden;
    }

    .page-header-content {
        padding: 14rem 0 2rem;
    }
}

/*@media screen and (min-width: 1280px) {
    .page-header-wrapper {
        max-height: 600px;
        overflow: hidden;
    }
    .page-header-content {
        padding: 16rem 0 2rem;
    }
}*/

/*@media screen and (min-width: 1440px) {
    .page-header-wrapper {
        max-height: 600px;
        overflow: hidden;
    }
    .page-header-content {
        padding: 18rem 0 2rem;
    }
}*/

@media screen and (max-width: 500px) {
    .page-header-wrapper {
        min-height: 320px;
        img {
            height: 320px;
            min-height: 320px;
            width: auto;
        }
    }

    .page-header-content {
        padding: 9rem 0 2rem;
    }
    .home-header-content {
        padding: 6rem 0 2rem;
    }
}


