.section-details-wrapper{
    //margin-top: 2rem;
}

.section-wrapper{
    padding: 3rem 0;
}

.section-header-wrap {
    text-align: center;
    padding-bottom: 2rem;

    .section-heading {
        padding: 1rem 0;
        color: $base-color-ev;
    }

    .section-heading-underline {
        height: 2px;
        background-color: $accent-color-ev;
        width: 100px;
        margin: 0 auto;
    }
}

.section-cta {
    text-align: center;
    margin: 1rem auto 0;

    .button {
        font-family: $button-font;
    }
}


