.artist-bio-content {
    margin-bottom: 2rem;
}


@media screen and (min-width: 768px) {
    .artist-info-wrap {
        @include display-flex(space-between, flex-start);

        .artist-bio-content {
            flex-basis: 60%;
        }
        .artist-bio-img-wrap {
            flex-basis: 35%;
        }
    }
}
