.grecaptcha-badge { visibility: hidden !important; }

#front-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    padding-top: 1rem;
}

.top-nav-header{
    @include display-flex(space-between, center);
    color: $white-color-ev;
}

.logo-wrapper {
    flex-basis: 170px;

    .nav-text-logo {
        font-family: $heading-font-bold;
        font-size: 1.5em;
        color: $accent-color-ev;
    }
}

.top-nav-hamburger {
    background: none;
    cursor: pointer;
    .mdi {
        color: $white-color-ev;
        font-size: 2em;
    }
}

.top-nav-links-wrapper {
    max-height: 0;
    overflow: hidden;
    background-color: rgba(38,1,1,0.9);
    transition: all ease-in-out .2s;
    li {
        a {
            display: block;
            padding: 1rem;
            color: $white-color-ev;
        }

        .nav-underline {
            display: block;
            width: 0;
            height: 1px;
            background-color: $dark-accent-color-ev;
            transition: all ease-in-out .15s;
        }

        &:hover {
            .nav-underline {
                width: 100%;
            }
        }

        &.active {
            .nav-underline {
                width: 100%;
                background-color: $accent-color-ev;
            }
        }

    }

    .gallery {
        display: block;
        margin: 0.5rem;
    }
}



@media screen and (min-width: 768px) {
    .top-nav-wrapper{
        @include display-flex(space-between, center);
    }

    .top-nav-header{
        display: block;
        flex-basis: 200px;
    }

    .top-nav-hamburger {
        display: none;
    }

    .top-nav-links-wrapper {
        @include display-flex(space-between, center);
        max-height: 50px;
        overflow: visible;
        background: none;
        li {
            .nav-underline {
                display: block;
                width: 0;
                margin: 0 auto;
                height: 2px;
                background-color: $dark-accent-color-ev;
                transition: all ease-in-out .15s;
            }

            &:hover {
                .nav-underline {
                    width: 70%;
                }
            }
            &.active {
                .nav-underline {
                    width: 70%;
                    background-color: $accent-color-ev;
                }
            }
        }

        .gallery {
            display: block;
            margin: 0 0 0 1rem;
        }
    }
}

