.gallery-showcase-section {
    .gallery-showcase-img-wrap {
        margin-bottom: 2rem;
        position: relative;

        .artwork-availability {
            position: absolute;
            top: 0;
            right: 0.5rem;
            background-color: $error-color-ev;
            p {
                padding: 0.2rem 0.5rem;
                color: $white-color-ev;
            }
        }
    }


    .section-cta {
        margin: 0.5rem auto 0;
    }
}



.gallery-filter-title-wrap {
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;

    li {
        display: block;
        color: $base-color-ev;
        font-family: $heading-font-bold;
        padding: 10px;
        flex-basis: 45%;
        cursor: pointer;

        border: 1px solid $base-color-ev;
        margin-bottom: 1rem;

        &:hover {
            background-color: $base-color-ev;
            color: white;
        }

        &.current {
            background-color: $base-color-ev;
            color: white;
        }
    }
}


.gallery-filter {
    display: none;
    text-align: center;
    padding: 2rem 2rem 4rem;
    border-top: 2px solid $base-color-ev;

    p {
        color: $dark-base-color-ev;
    }


    &.current {
        //display: block;
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 1rem;
        padding: 1rem;

        @media screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 1rem;
        }

        @media screen and (min-width: 1280px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

    }
}



@media screen and (min-width: 769px) {
    .gallery-filter-title-wrap {
        justify-content: flex-start;
        flex-wrap: nowrap;

        li {
            flex-basis: 24%;
            margin-bottom: 0;
            margin-right: 1rem;
            border-top: 1px solid $base-color-ev;
            border-left: 1px solid $base-color-ev;
            border-right: 1px solid $base-color-ev;
            border-bottom: none;
        }
    }

    .gallery-filter {

    }

    .gallery-showcase-img-wrap {
        text-align: left;
        flex-basis: 25%;
        padding-right: 1rem;
    }

}

