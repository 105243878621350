#front-footer {
    background-color: $dark-secondary-color-ev;
    color: $white-color-ev;
    width: 100%;
    padding: 3rem 0 0;


    .footer-heading{
        color: $white-color-ev;
        padding-bottom: 1rem;
    }
}

.social-icons-wrapper {
    padding: 3rem 0;
}

.social-wrap, .contact-info-wrap{
    padding-bottom: 2rem;
    text-align: center;
}


.social-wrap{
    @include display-flex(center, center);
    .social-icon {
        padding: 0 2rem;
        font-size: 1.5em;
        a {
            cursor: pointer;
            color: $light-gray-color-ev;

            &:hover {
                color: $accent-color-ev;
            }
        }
    }
}


.contact-info-wrap{
    .contact-info-icon {
        font-size: 1.2em;
    }
    p {
        padding: 0.1rem 0;
        margin: 0;
        font-size: 1.2em;
    }
}


.copyright-wrap{
    border-top: 1px solid $mid-gray-color-ev;
    padding: 0.6rem 0;
    color: $mid-gray-color-ev;
    text-align: center;
}


@media screen and (min-width:992px) {
    .footer-content-wrap{
        @include display-flex(space-around, flex-start);
    }

    .social-wrap, .contact-info-wrap{
        padding: 1rem 0;
    }
}
