.overview-section {
    background-color: $light-gray-color-ev;
    p {
        text-align: center;
    }
}

.artwork-showcase-wrap {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 1rem;
    padding: 1rem;

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
    }

    @media screen and (min-width: 1280px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}


.contact-form-section {
    background-color: $accent-color-ev;

    .contact-form-heading {
        text-align: center;
        color: $white-color-ev;
        padding-bottom: 1rem;
    }

    .elabs-form-label {
        color: $white-color-ev;
    }

    .contact-section-cta {
        margin-top: 3rem;
    }

    .contact-form-button {
        border: 2px solid $white-color-ev;
        padding: 7px 3rem;
    }
}




