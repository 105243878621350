

.order-img-wrap {
    margin-bottom: 3rem;
}


@media screen and (min-width: 768px) {
    .place-order-form-wrap {
        @include display-flex(space-between, flex-start)
    }

    form {
        flex-basis: 60%;
    };

    .order-img-wrap {
        flex-basis: 35%;
    }
}
