
.main-contact-form-section {
    .contact-section-cta {
        margin-top: 3rem;
    }

    .contact-form-button {
        border: 2px solid $white-color-ev;
        padding: 7px 3rem;
    }
}




